@import '../../../../scss/theme-bootstrap';

.sticky-footer-live-chat {
  display: block;
  margin-top: 2px;
  #{$ldirection}: auto;
  #{$rdirection}: 15px;
  position: fixed;
  top: 77%;
  width: auto;
  z-index: 100;
  @media #{$cr19-large-up} {
    margin-top: 0;
    #{$rdirection}: 40px;
    top: 88%;
  }
  a {
    @include border-radius(50%);
    @include transition(opacity 0.4s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out);
    background-color: $cr19-bg-black;
    border: 1px solid $cr19-border-light;
    box-sizing: border-box;
    height: 45px;
    opacity: 1;
    pointer-events: auto;
    position: fixed;
    #{$rdirection}: 15px;
    text-align: center;
    top: 80%;
    width: 45px;
    z-index: 100;
    @media #{$cr19-large-up} {
      top: 85%;
      #{$ldirection}: auto;
      #{$rdirection}: 40px;
    }
    .page-product & {
      top: 65%;
      @media #{$cr19-xsmall-up} {
        top: 67%;
      }
      @media #{$cr19-small-up} {
        top: 50%;
      }
      @media #{$cr19-medium-up} {
        top: 72%;
      }
      @media #{$cr19-large-up} {
        top: 78%;
      }
    }
    &:hover {
      background: $cr19-border-light;
    }
  }
  svg {
    fill: $color-white;
    height: 18px;
    #{$ldirection}: 0;
    position: relative;
    top: 14px;
    width: 20px;
  }
}
